import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconColor = presets.dark
const LineWidth = presets.LineWidth
const IconWidth = presets.IconWidth

const IconDustlessSanding = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke: ${IconColor};
      stroke-width: ${LineWidth};
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .st1 {
      fill: none;
      stroke: ${IconColor};
      stroke-width: ${LineWidth};
    }
    .st2 {
      fill: none;
      stroke: ${IconColor};
      stroke-width: ${LineWidth};
      stroke-linejoin: round;
    }
    .st3 {
      fill: none;
      stroke: ${IconColor};
      stroke-width: ${LineWidth};
      stroke-linecap: round;
    }
  }
`

export default () => {
  return (
    <IconDustlessSanding>
      <svg
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        preserveAspectRatio="xMidYMin"
      >
        <circle className="st1" cx="11" cy="9" r="4" />
        <polyline className="st2" points="8.5,12 10,18.1 12,18.1 13.5,12 " />
        <polygon className="st2" points="37,27 25,27 25,21 28,19 34,19 37,21 " />
        <rect x="25" y="29" className="st2" width="12" height="12" />
        <polyline className="st0" points="11,21 11,31 19,31 19,13 31,13 31,16 " />
        <line className="st3" x1="27" y1="41.2" x2="27" y2="43" />
        <line className="st3" x1="35" y1="41.2" x2="35" y2="43" />
      </svg>
    </IconDustlessSanding>
  )
}
