import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const width = presets.IconWidth
const color = presets.dark
const lineWidth = presets.LineWidth

const Icon = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${width};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke: ${color};
      stroke-width: ${lineWidth};
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .st1 {
      fill: none;
      stroke: ${color};
      stroke-width: ${lineWidth};
      stroke-linejoin: round;
    }
  }
`

export default () => {
  return (
    <Icon>
      <svg
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        preserveAspectRatio="xMidYMin"
      >
        <path
          className="st1"
          d="M4,26C4,26,4.1,6,24,6s20,19.9,20,19.9S41.1,24,40,24c-1.1,0-3.9,2-3.9,2s-3-2.1-4.1-2.1c-1.1,0-4.1,2-4.1,2
	S25.1,24,24,24c-1.1,0-4.1,2-4.1,2s-2.8-2-3.9-2c-1.1,0-4,2-4,2s-2.9-2-4-2S4,26,4,26z"
        />
        <path className="st0" d="M24,28v14c0,0,0,3.9,3.9,3.9C32,45.9,32,42,32,42" />
      </svg>
    </Icon>
  )
}
