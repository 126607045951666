import React from 'react'
import styled from 'styled-components'
import presets from '../utils/presets'
import {rhythm} from '../utils/typography'
import {Link} from 'gatsby'

const Cta = styled.div`
  display:inline-block;
  margin: 0 auto;
  margin-bottom: ${rhythm(1)};
  border: 2px solid ${props => props.brand ? presets.brand : presets.dark };
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: ${rhythm(0.1)};
  font-weight: 900;
  background-color: ${props => props.brand ? presets.brand : 'transparent' };
  padding: ${rhythm(0.5)} 0;
  a{
    padding: ${rhythm(0.5)} ${rhythm(1)};
    color: ${props => props.brand ? presets.dark : presets.dark };
    text-decoration: none;
  }
`;

export default (props) => {
  return(
    <Cta {...props}>
      <Link to={props.to}>{props.children}</Link>
    </Cta>
  );
}
