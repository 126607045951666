import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const H3 = styled.h3`
  margin:0 auto;
  margin-bottom: ${rhythm(1)};
  text-transform: capitalize;
  ${'' /* text-align: ${props => props.center ? 'center' : 'left'}; */}
`;

export default H3
