import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const Grid = styled.section`
  /* width: calc( 100% - ${rhythm(2)} ); */
  /* max-width: 1280px; */
  /* margin: ${rhythm(2)} auto; */
  /* padding: 0 ${rhythm(1)}; */
  ${'' /* background-color: grey; */}
  /* width:100%; */
  @supports(display:grid){
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
    grid-gap: ${rhythm(3)};
  }

  ${'' /* @media screen and (min-device-width: 0){
      min-height: 80vh;
    }
  @media screen and (min-device-width: 750px){
      min-height: 50vh;
    } */}
`;

export default Grid
