import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconWidth = presets.IconWidth
const color = presets.dark
const stroke = presets.LineWidth

const Icon = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke: ${color};
      stroke-width: ${stroke};
      stroke-linejoin: round;
    }
  }
`

export default () => {
  return (
    <Icon>
      <svg x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" preserveAspectRatio="xMidYMin">
        <path
          className="st0"
          d="M36.8,34.1c-2.7-3.8-7-7.4-7.9-8.6c-1-1.3-1.7-3-2.1-4.4c-0.5-1.5,0.5-2.4,0.5-3c0-0.3-3.3-3.7-3.3-3.7
	s-1.1-3.9-1.1-7.1C23,4.1,23.3,3,23.3,3H6.4c0,0,0,7.5,5.8,15.6c0,0,0.1,0.1,0.1,0.1v0.4c0,0,3.2,4.9,3.7,5.1c0.4,0.1,2-0.5,3.5,0
	c1.5,0.5,4,2.3,5.1,3.5c1.2,1.2,4.5,6.6,7,10.1c2.5,3.5,3.9,5,5,5.8c1.1,0.9,1.8,1.4,2.1,1.4c0.3,0,0.8-0.3,1.1-0.4
	c0.5-0.3,1.6-1,1.7-1.4c0.1-0.4-0.5-2-1.5-3.8C39.2,37.5,38.8,36.9,36.8,34.1z"
        />
      </svg>
    </Icon>
  )
}
