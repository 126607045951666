import styled from 'styled-components'
import {rhythm} from '../utils/typography'

const Card = styled.div`
  text-align: center;
  /* background-color: #f0f0f0; */
  /* width:100%; */
  /* position: relative; */
  h3{
    margin:${rhythm(1)} 0;
    text-transform:capitalize;
  }
  div.imagePlaceHolder{
    /* background-color: #f5f5f5; */
    width: 160px;
    height: auto;
    min-width: 160px;
    /* border-radius: 50%; */
    display:inline-block;
  }
`;

export default Card
