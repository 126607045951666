import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconWidth = presets.IconWidth
const color = presets.dark
const stroke = presets.LineWidth

const Icon = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke: ${color};
      stroke-width: ${stroke};
    }
    .st1 {
      fill: ${color};
    }
    .st2 {
      fill: none;
      stroke: ${color};
      stroke-width: ${stroke};
      stroke-linecap: round;
    }
  }
`

export default () => {
  return (
    <Icon>
      <svg x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" preserveAspectRatio="xMidYMin">
        <circle className="st0" cx="24" cy="24" r="21.2" />
        <line className="st2" x1="24" y1="6" x2="24" y2="10" />
        <line className="st2" x1="24" y1="38" x2="24" y2="42" />
        <line className="st2" x1="6" y1="24" x2="10" y2="24" />
        <line className="st2" x1="38" y1="24" x2="42" y2="24" />
        <line className="st2" x1="12.7" y1="12.7" x2="24" y2="24" />
        <line className="st2" x1="24" y1="24" x2="29.7" y2="18.3" />
        <circle className="st1" cx="24" cy="24" r="2" />
      </svg>
    </Icon>
  )
}
