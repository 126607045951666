import React from 'react'
import presets from '../utils/presets'
import { rhythm } from '../utils/typography'
// import Link from 'gatsby-link'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

// new
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

// import capitalize from 'capitalize'

import Container from '../com/Container'
import Grid from '../com/Grid'
// import FlexCenter from '../com/FlexCenter'
import Card from '../com/Card'
import Footer from '../com/Footer'
import Diagonal from '../com/Diagonal'

import Cta from '../com/Cta'

import Section from '../com/Section'
import H1 from '../com/H1'
import H2 from '../com/H2'
import H3 from '../com/H3'
import P from '../com/P'

import IconPin from '../com/IconPin'

import LayoutMain from '../com/LayoutMain'
// import Button from '../com/Button'

//import icons
import IconEstateAgent from '../com/icons/IconEstateAgent'
import IconDomestic from '../com/icons/IconDomestic'
import IconDustlessSanding from '../com/icons/IconDustlessSanding'
import IconExperience from '../com/icons/IconExperience'
import IconInsurance from '../com/icons/IconInsurance'
import IconKey from '../com/icons/IconKey'
import IconPainting from '../com/icons/IconPainting'
import IconReliable from '../com/icons/IconReliable'
import IconScaffolding from '../com/icons/IconScaffolding'
import IconWallpapering from '../com/icons/IconWallpapering'

const IndexPage = ({ data }) => {
  return (
    <LayoutMain>
      <Helmet>
        <title>
          {data.site.siteMetadata.shortDescription} | {data.site.siteMetadata.title}
        </title>
        {/* <meta name="description" content="Say Hi to start a project, or just have a chat." /> */}
        <meta
          name="description"
          content={`${data.site.siteMetadata.shortDescription}. Say Hi to start a project, or just have a chat.`}
        />
        <link rel="canonical" href={data.site.siteMetadata.url} />

        {/* open graph tags */}
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.shortDescription} />
        <meta property="og:url" content={data.site.siteMetadata.url} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        {/* the image */}
        <meta property="og:image" content={`${data.site.siteMetadata.url}${data.ogimage.original.src}`} />
        <meta property="og:image:url" content={`${data.site.siteMetadata.url}${data.ogimage.original.src}`} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:alt" content="Dec Hands Logo" />
        <meta property="og:image:width" content={data.ogimage.original.width} />
        <meta property="og:image:height" content={data.ogimage.original.height} />
        <meta property="og:image:alt" content={data.site.siteMetadata.title} />
        <meta property="og:image:secure_url" content={`${data.site.siteMetadata.url}${data.ogimage.original.src}`} />
        {/* local */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:locale:alternate" content="en_US" />
        {/* end open graph tags */}
      </Helmet>
      <header>
        <Diagonal>
          <Container>
            {/* <div id="restrict" style={{width:'80%',}}> */}
            <h2>Painting, Decorating, Wallpapering</h2>
            <P>
              <IconPin /> In the Salisbury Area
            </P>
            <h2>Estate Agents, Landlords, Domestic</h2>
            <Cta to="/contact/">contact</Cta>
            {/* </div> */}
          </Container>
        </Diagonal>
      </header>

      <Section>
        <Container center>
          <br />
          <H1>Decorating? Think Dec Hands.</H1>
          <H3 style={{ color: `${presets.brandDark}` }}>Proudly Decorating for Estate Agents in Salisbury</H3>
          {/* <FlexCenter>
            <span>Martin &amp; Co</span>
            <span>Bassets</span>
            <span>piccolo</span>
            <span>Fox &amp; Sons</span>
            <span>Oliver Chandler</span>
          </FlexCenter> */}
        </Container>
      </Section>

      <Section>
        <Container center>
          <H2 id="about">About Dec Hands</H2>
          {/* <hr /> */}
          <Img
            sizes={data.lydia.fluid}
            alt={data.lydia.fluid.originalName}
            title={data.lydia.fluid.originalName}
            style={{
              width: `60%`,
              borderRadius: `50%`,
              marginLeft: `auto`,
              marginRight: `auto`,
              marginBottom: `${rhythm(1)}`,
              maxWidth: `${rhythm(10)}`,
            }}
          />
          <P caption>Lydia Taylor, MD</P>
          <P>
            &ldquo;At Dec Hands we pride ourselves on extremely high-quality interior decorating, a professional,
            trustworthy and friendly service while keeping everything clean and tidy.&rdquo;
          </P>
        </Container>
      </Section>

      <Section>
        <Container center>
          <H2>Who do we work with?</H2>
          <Grid>
            <Card>
              <div className="imagePlaceHolder">
                <IconEstateAgent />
              </div>
              <h3>Estate Agents &amp; Landlords</h3>
              <P>Cost effective with fast turnarounds</P>
            </Card>
            <Card>
              <div className="imagePlaceHolder">
                <IconDomestic />
              </div>
              <h3>Domestic</h3>
              <P>Friendly and trustworthy teams. Extensive knowledge of paint types and application techniques</P>
            </Card>
            <Card>
              <div className="imagePlaceHolder">
                <IconInsurance />
              </div>
              <h3>Insurance</h3>
              <P>Quick responses and flexible integration with other service providers</P>
            </Card>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Container center>
          <H2>why choose dec hands?</H2>
          <Grid>
            <Card>
              <div className="imagePlaceHolder">
                <IconKey />
              </div>
              <h3>Flexible &amp; Trustworthy</h3>
              <P>Cost effective with fast turnarounds</P>
            </Card>
            <Card>
              <div className="imagePlaceHolder">
                <IconReliable />
              </div>
              <h3>reliable</h3>
              <P>We are punctual and keep to deadlines</P>
            </Card>
            <Card>
              <div className="imagePlaceHolder">
                <IconExperience />
              </div>
              <h3>years of experience</h3>
              <P>Professionally trained with extensive experience in the field</P>
            </Card>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Container center>
          <H2>our services</H2>
          <Grid>
            <Card>
              <div className="imagePlaceHolder">
                <IconPainting />
              </div>
              <h3>painting</h3>
              <P>Professionally trained with extensive experience in the field</P>
            </Card>
            <Card>
              <div className="imagePlaceHolder">
                <IconWallpapering />
              </div>
              <h3>wallpapering</h3>
              <P>
                A range of wall coverings. Knowledge of application techniques. Fully lined for best finish and
                longevity
              </P>
            </Card>
            <Card>
              <div className="imagePlaceHolder">
                <IconDustlessSanding />
              </div>
              <h3>dustless sanding</h3>
              <P>Faster &amp; higher quality with a cleaner property during and after work</P>
            </Card>
            <Card>
              <div className="imagePlaceHolder">
                <IconScaffolding />
              </div>
              <h3>scaffolding</h3>
              <P>We arrange scaffolding if needed. We work closely with local companies</P>
            </Card>
          </Grid>
        </Container>
      </Section>

      <Section>
        <Container center>
          <H3>special requirements?</H3>
          <P>
            That's not all we can do. If you have a different project you'd like to make,{' '}
            <Link to="/contact/">gives us a call</Link>. We'd love the chance to help you achieve what you need.
          </P>
        </Container>
      </Section>

      <Container center>
        <H2 center id="work">
          See Our Work
        </H2>
        <P>
          <a
            href="https://www.facebook.com/Dec-Hands-Ltd-779659805434007/photos"
            target="_blank"
            rel="noopener noreferrer"
          >
            View some of our handy work on our Facebook gallery.
          </a>
        </P>
        <Cta to="/contact/" brand>
          Contact Us
        </Cta>

      </Container>
      <Section>
      <Container>
        <Footer />
      </Container>
      </Section>
    </LayoutMain>
  )
}

export const query = graphql`
  query {
    lydia: imageSharp(fluid: { originalName: { regex: "/lydia-portrait-turquoise/" } }) {
      fixed(width: 1440, height: 1440, quality: 50, cropFocus: NORTH) {
        ...GatsbyImageSharpFixed
        originalName
      }
      fluid(maxWidth: 2560, maxHeight: 2560, quality: 60, cropFocus: NORTH) {
        ...GatsbyImageSharpFluid
        originalName
      }
    }
    site {
      siteMetadata {
        title
        url
        description
        shortDescription
      }
    }
    ogimage: imageSharp(fluid: { originalName: { regex: "/icon/" } }) {
      original {
        height
        width
        src
      }
    }
  }
`

export default IndexPage
