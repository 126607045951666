import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconColor = presets.dark
const LineWidth = presets.LineWidth
const IconWidth = presets.IconWidth

const IconDomestic = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0,
    .st1 {
      fill: none;
      stroke: ${IconColor};
      stroke-width: ${LineWidth};
      stroke-linejoin: round;
    }
    .st0 {
      stroke-linecap: round;
    }
  }
`

export default () => {
  return (
    <IconDomestic>
      <svg
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        preserveAspectRatio="xMidYMin"
      >
        <g>
          <g>
            <polyline
              className="st0"
              points="8.5,47.3 8.5,35.1 8.5,32.2 5.7,32.2 21.6,18.7 28.9,25.1 28.9,20.8 34.7,20.8 34.7,29.9 37.6,32.2 34.7,32.2 34.7,35.1 34.7,47.3 		"
            />
          </g>
          <g>
            <polyline
              className="st0"
              points="24.3,47.3 24.3,39.2 18.9,39.2 18.9,47.3 		"
            />
          </g>
        </g>
        <g>
          <polygon
            className="st1"
            points="6.6,47.3 4.6,47.3 4.6,43.2 5.6,42.2 6.6,43.2 6.6,47.3 	"
          />
        </g>
        <g>
          <polygon
            className="st1"
            points="3.6,47.3 1.6,47.3 1.6,43.2 2.6,42.2 3.6,43.2 3.6,47.3 	"
          />
        </g>
        <g>
          <polygon
            className="st1"
            points="38.4,47.3 36.4,47.3 36.4,43.2 37.4,42.2 38.4,43.2 38.4,47.3 	"
          />
        </g>
        <g>
          <polygon
            className="st1"
            points="41.3,47.3 39.3,47.3 39.3,43.2 40.3,42.2 41.3,43.2 41.3,47.3 	"
          />
        </g>
        <g>
          <polygon
            className="st1"
            points="44.1,47.3 42.1,47.3 42.1,43.2 43.1,42.2 44.1,43.2 44.1,47.3 	"
          />
        </g>
        <g>
          <polygon
            className="st1"
            points="47,47.3 45,47.3 45,43.2 46,42.2 47,43.2 47,47.3 	"
          />
        </g>
      </svg>
    </IconDomestic>
  )
}
