import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconWidth = presets.IconWidth
const color = presets.dark
const stroke = presets.LineWidth

const Icon = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke: ${color};
      stroke-width: ${stroke};
      stroke-linejoin: round;
    }
    .st1 {
      fill: ${color};
    }
    .st2 {
      fill: none;
      stroke: ${color};
      stroke-width: ${stroke};
      stroke-linecap: round;
    }
  }
`

export default () => {
  return (
    <Icon>
      <svg x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" preserveAspectRatio="xMidYMin">
        <path
          className="st0"
          d="M29,24l-2.2-1.8l2.2-2.1l-2.1-1.9L29,16l-2.1-2l2.1-2.1V10l-5-5.1L19,10v16.4c-2.4,1.6-4,4.4-4,7.5c0,5,4,9,9,9
	c5,0,9-4,9-9c0-3.1-1.6-5.9-4-7.5V24z"
        />
        <line className="st2" x1="22.2" y1="11.3" x2="22.2" y2="25.9" />
        <circle className="st1" cx="24" cy="37.5" r="2.5" />
      </svg>
    </Icon>
  )
}
