import styled from 'styled-components'
import presets from '../utils/presets'
import {rhythm} from '../utils/typography'

const IconPin = styled.span`
  color: ${presets.dark};
  position: relative;
  display: inline-block;
  margin-left: 4px;
  margin-top: 2px;
  width: ${rhythm(0.5)};
  height: ${rhythm(0.5)};
  border: solid 1px currentColor;
  border-radius: 7px 7px 7px 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  &::before {
    content: '';
    position: absolute;
    left: ${rhythm(0.125)};
    top: ${rhythm(0.125)};
    width: ${rhythm(0.175)};
    height: ${rhythm(0.175)};
    border: solid 1px currentColor;
    border-radius: 3px;
  }
`;
export default IconPin
