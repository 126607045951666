import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconWidth = presets.IconWidth
const IconColor = presets.dark
// const LineWidth = presets.LineWidth

const IconExperience = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke: ${IconColor};
      stroke-linejoin: round;
    }
  }
`

export default () => {
  return (
    <IconExperience>
      <svg
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        preserveAspectRatio="xMidYMin"
      >
        <path className="st0" d="M4.5,10.6h11v27.1H9.7V15.8H4.5V10.6z" />
        <path
          className="st0"
          d="M32.3,38c-3.7,0-6.6-1.3-8.4-3.8c-1.9-2.5-2.8-5.9-2.8-10.2c0-4.3,0.9-7.6,2.8-10.2c1.9-2.5,4.7-3.8,8.4-3.8 c3.7,0,6.6,1.3,8.4,3.8c1.9,2.5,2.8,5.9,2.8,10.2c0,4.3-0.9,7.6-2.8,10.2C38.8,36.7,36,38,32.3,38z M28.3,17.5 C27.4,19.1,27,21.3,27,24c0,2.7,0.4,4.9,1.3,6.6c0.9,1.7,2.2,2.5,4,2.5c1.8,0,3.1-0.8,4-2.5c0.9-1.7,1.3-3.8,1.3-6.6 	c0-2.7-0.4-4.9-1.3-6.6c-0.9-1.7-2.2-2.5-4-2.5C30.5,15,29.2,15.8,28.3,17.5z"
        />
      </svg>
    </IconExperience>
  )
}
