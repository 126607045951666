import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconWidth = presets.IconWidth
const color = presets.dark
const stroke = presets.LineWidth

const Icon = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke:${color};
      stroke-width: ${stroke};
    }
  }
`

export default () => {
  return (
    <Icon>
      <svg x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" preserveAspectRatio="xMidYMin">
        <line className="st0" x1="7" y1="44" x2="7" y2="6" />
        <line className="st0" x1="24" y1="44" x2="24" y2="6" />
        <line className="st0" x1="41" y1="44" x2="41" y2="6" />
        <line className="st0" x1="4" y1="45" x2="10" y2="45" />
        <line className="st0" x1="21" y1="45" x2="27" y2="45" />
        <line className="st0" x1="38" y1="45" x2="44" y2="45" />
        <line className="st0" x1="7" y1="33" x2="41" y2="33" />
        <line className="st0" x1="7" y1="27" x2="41" y2="27" />
        <line className="st0" x1="7" y1="19" x2="41" y2="19" />
        <line className="st0" x1="7" y1="12" x2="41" y2="12" />
        <line className="st0" x1="7" y1="19" x2="24" y2="12" />
        <line className="st0" x1="41" y1="19" x2="24" y2="12" />
        <line className="st0" x1="7" y1="33" x2="24" y2="27" />
        <line className="st0" x1="41" y1="33" x2="24" y2="27" />
      </svg>
    </Icon>
  )
}
