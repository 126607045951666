import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

const IconWidth = presets.IconWidth
const color = presets.dark
const stroke = presets.LineWidth

const Icon = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0 {
      fill: none;
      stroke: ${color};
      stroke-width: ${stroke};
      stroke-linejoin: round;
    }
    .st1 {
      fill: none;
      stroke: ${color};
      stroke-width: ${stroke};
    }
  }
`

export default () => {
  return (
    <Icon>
      <svg x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" preserveAspectRatio="xMidYMin">
        <polygon className="st0" points="32,16 32,4 4,4 4,44 44,44 44,16 " />
        <line className="st1" x1="28.1" y1="4" x2="28.1" y2="44" />
        <line className="st1" x1="13.1" y1="4" x2="13.1" y2="44" />
        <line className="st1" x1="32" y1="4" x2="44" y2="16" />
      </svg>
    </Icon>
  )
}
