import React from 'react'
import styled from 'styled-components'
import presets from '../../utils/presets'

// const IconColor = presets.dark
// const LineWidth = presets.LineWidth
const IconWidth = presets.IconWidth

const IconEstateAgent = styled.span`
  display: inline-block;
  line-height: 1;
  width: ${IconWidth};
  svg {
    position: relative;
    display: inline-block;
    margin: 0;
    width: 100%;
    height: auto;
    .st0,
    .st1 {
      fill: none;
      stroke: ${presets.dark};
      stroke-width: 1;
      stroke-linejoin: round;
    }
    .st0 {
      stroke-linecap: round;
    }
  }
`

export default () => {
  return (
    <IconEstateAgent>
      <svg
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        preserveAspectRatio="xMidYMin"
      >
        <polyline
          className="st1"
          points="5.5,47.3 5.5,35.1 5.5,32.2 2.7,32.2 18.6,18.7 25.9,25.1 25.9,20.8 31.7,20.8 31.7,29.9 34.6,32.2 31.7,32.2 31.7,35.1 31.7,47.3"
        />

        <polyline
          className="st1"
          points="21.3,47.3 21.3,39.2 15.9,39.2 15.9,47.3"
        />

        <polyline
          className="st1"
          points="20.6,21.2 28.9,14.1 35.8,20.2 35.8,16.2 41.4,16.2 41.4,24.8 44.1,27 41.4,27 41.4,29.7 41.4,41.3"
        />

        <polyline className="st0" points="31.5,16.2 39.8,9.1 46.8,15.2 		" />
      </svg>
    </IconEstateAgent>
  )
}
